import { HeadFC, navigate } from 'gatsby';
import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import chevronRight from '../../assets/chevron-right.svg';
import circuloCruzado from '../../assets/circulo-cruzado.svg';
import lista from '../../assets/lista.svg';
import { Button, ButtonOutline } from '../../components/Button';
import { Body2 } from '../../components/Fonts/Body';
import { Footnote } from '../../components/Fonts/Footnote';
import { H4 } from '../../components/Fonts/HTags';
import { Subtitle } from '../../components/Fonts/Subtitle';
import Footer from '../../components/Footer';
import { Line } from '../../components/Line';
import { Loading } from '../../components/Loading';
import NavBar from '../../components/Nav';
import PlanCard from '../../components/PlanCard';
import { Seo } from '../../components/Seo';
import { Spacer } from '../../components/Spacer';
import { View } from '../../components/View';
import { fontWeightType } from '../../constants/enums';
import { useAuth } from '../../hooks/useAuth';
import Base from '../../layout/Base';
import server from '../../services/fetchApi';
import { sentryCaptureException } from '../../services/sentry';

const UserInfo = styled.nav`
  background: transparent;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.mainAguaColor};
  border-radius: 15px;
  display: flex;
  flex-direction: row;
`;

const PictureUser = styled.div`
  background-color: ${({ theme }) => theme.colors.mainAguaColor};
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.simpleWhite};
  border-radius: 100%;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PlansPage = () => {
  useAuth();
  const [currentUser, setCurrentUser] = useState<any>();
  const [loading, setLoading] = useState(true);

  const fetchUser = async () => {
    try {
      const response = await server.get('api/v1/users/get_user');
      setCurrentUser(response.data);
    } catch (error) {
      sentryCaptureException(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const postMessageToApp = () => {
    navigate(-1);
  };

  const { first_name, last_name, email } = currentUser?.user || {
    first_name: '',
    last_name: '',
    email: '',
  };

  const isPending = currentUser?.active_subscription?.status_subscription === 'pending';
  const plan = currentUser?.active_subscription?.plan;
  const isFreemium = plan?.plan_type === 'free';
  const noPlan =
    !currentUser?.active_subscription?.is_freemium &&
    (plan?.plan_type === undefined ||
      currentUser?.active_subscription?.freemium_expired ||
      currentUser?.active_subscription?.expired ||
      currentUser?.active_subscription?.state === 'inactive' ||
      !currentUser?.active_subscription?.active);

  const navigateWithToken = (route: string) => {
    navigate(`/${route}`);
  };

  if (loading) {
    return (
      <Loading
        onPress={() => null}
        mainTitle="Por favor espere un momento..."
        type="loading"
        buttonLabel=""
      />
    );
  }

  return (
    <Base>
      <NavBar>
        <UserInfo>
          <PictureUser>
            {first_name[0]}
            {last_name[0]}
          </PictureUser>
          <Spacer size="8" />
          <div>
            <Subtitle color="simpleWhite" fontWeight={fontWeightType.Bold}>
              {first_name} {last_name}
            </Subtitle>
            <Spacer size="3" />
            <Body2 color="simpleWhite" fontWeight={fontWeightType.Medium}>
              {email}
            </Body2>
          </div>
        </UserInfo>
      </NavBar>
      <View>
        <Spacer size="30" />
        <H4 color="grayBlack" fontWeight={fontWeightType.Bold}>
          {isPending ? 'Tu pago esta siendo procesado' : noPlan ? 'Sin plan' : 'Tu plan'}
        </H4>
        <Spacer size="18" />
        {noPlan ? (
          <Body2 fontWeight={fontWeightType.Medium}>Actualmente no cuentas con un Plan</Body2>
        ) : (
          <PlanCard
            plan={plan}
            hideButton
            subscriptionPeriodEnd={currentUser?.active_subscription?.current_period_end}
          />
        )}
        <Spacer size="15" />
        <H4 color="grayBlack" fontWeight={fontWeightType.Bold}>
          Opciones
        </H4>
        <Spacer size="20" />
        {isFreemium || noPlan ? (
          <>
            <Line />
            <Spacer size="20" />
            <ButtonContainer>
              <ButtonOutline type="button" onClick={postMessageToApp}>
                Regresar
              </ButtonOutline>
              {isPending ? null : (
                <>
                  <Spacer size="30" />
                  <Button
                    type="button"
                    onClick={() => {
                      navigateWithToken('plans');
                    }}>
                    Ver Planes
                  </Button>
                </>
              )}
            </ButtonContainer>
            <Spacer size="20" />
            <Line />
            <Spacer size="20" />
          </>
        ) : (
          <>
            {/* <OptionsContainer>
              <div>
                <img src={lista} alt="lista" />
                <Spacer size="15" />
                <Footnote color="grayBlack" fontWeight={fontWeightType.SemiBold}>Cambiar método de pago</Footnote>
              </div>
              <img src={chevronRight} alt="chevronRight" />
            </OptionsContainer>
            <Spacer size="20" />
            <Line />
            <Spacer size="20" /> */}
            <OptionsContainer>
              <div onClick={() => navigateWithToken('plans')}>
                <img src={lista} alt="lista" />
                <Spacer size="15" />
                <Footnote
                  color="grayBlack"
                  fontWeight={fontWeightType.SemiBold}
                  style={{ cursor: 'pointer' }}>
                  Ver Planes
                </Footnote>
              </div>
              <img src={chevronRight} alt="chevronRight" />
            </OptionsContainer>
            <Spacer size="20" />
            <Line />
            <Spacer size="20" />
            <OptionsContainer>
              <div onClick={() => navigateWithToken('cancel-plan')}>
                <img src={circuloCruzado} alt="circuloCruzado" />
                <Spacer size="15" />
                <Footnote
                  color="grayBlack"
                  fontWeight={fontWeightType.SemiBold}
                  style={{ cursor: 'pointer' }}>
                  Cancelar suscripción
                </Footnote>
              </div>
              <img src={chevronRight} alt="chevronRight" />
            </OptionsContainer>
            <Spacer size="20" />
            <Line />
            <Spacer size="21" />
          </>
        )}
      </View>
      <Footer />
    </Base>
  );
};

export default PlansPage;

export const Head: HeadFC = () => <Seo title="Carpediem MediAPP - Account" />;
