import { Link } from 'gatsby';
import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';

import { fontWeightType } from '../../constants/enums';
import { getUTCDate } from '../../helpers/time';
import { Button } from '../Button';
import { Caption1, Caption2 } from '../Fonts/Caption';
import { Subtitle } from '../Fonts/Subtitle';
import { Line } from '../Line';
import { Spacer } from '../Spacer';

export interface PlanCardProps {
  amount: string;
  id: string;
  interval: string;
  interval_count: string;
  name: string;
  plan_type: string;
  trial_days: string;
  created_at?: string;
}

interface PlanProps {
  plan: PlanCardProps;
  subscriptionPeriodEnd?: string;
  hideButton?: boolean;
}

const Background = styled.nav`
  background: ${({ theme }) => theme.colors.simpleWhite};
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.grayLink};
  border-radius: 15px;
  margin-bottom: 7px;
  cursor: pointer;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Amount = styled.div`
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.colors.mainAguaColor};
  border-radius: 30px;
`;

const TycText = styled(Link)`
  color: ${({ theme }) => theme.colors.mainAguaColor};
`;

const ButtonInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PlanCard: FC<PlanProps> = ({ plan, subscriptionPeriodEnd, hideButton }) => {
  const { amount, interval_count, name, plan_type, trial_days } = plan || {};

  const days = Math.round(parseInt(interval_count, 10));
  const trialDays = Math.round(parseInt(trial_days, 10));
  const amountFormat = Math.round(parseInt(amount, 10));
  const formatterPeso = Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0,
  });

  const isFreemium = plan_type === 'free';
  const formattedDate = getUTCDate(
    subscriptionPeriodEnd || new Date().toISOString(),
    isFreemium ? trialDays : 1,
  );

  const planText = isFreemium
    ? {
        title: 'Freemium',
        cost: 'Sin Costo',
        content: `Plan freemium gratuito por ${trialDays} días con acceso a 200 preguntas aproximadamente`,
        autoTest: '• No incluye examen de auto-evaluación',
        textDate: 'Fecha de vencimiento',
        date: formattedDate,
        tyc: 'Puedes adquirir cualquiera de nuestros planes de pago en el momento que desees. ',
      }
    : {
        title: name,
        cost: formatterPeso.format(amountFormat),
        content: `Acceso nuestro banco de +3500 preguntas de distintos temas de Medicina durante ${days} días.`,
        autoTest: `• Incluye ${1} examen de auto-evaluación`,
        textDate: 'Próxima fecha de facturación',
        date: formattedDate,
        tyc: 'Puedes cancelar tu plan en el momento que desees, ',
      };

  const { title, cost, content, autoTest, textDate, date, tyc } = planText;

  return (
    <Background>
      <InfoContainer>
        <Subtitle color="grayBlack" fontWeight={fontWeightType.ExtraBold}>
          {title}
        </Subtitle>
        <Amount>
          <Caption1 color="simpleWhite" fontWeight={fontWeightType.Bold}>
            {cost}
          </Caption1>
        </Amount>
      </InfoContainer>
      <Spacer size="15" />
      <Line />
      <Spacer size="15" />
      <ButtonInfoContainer>
        <Caption2 color="grayBlack" fontWeight={fontWeightType.Bold}>
          {content}
        </Caption2>
        <Spacer size="15" />
        {!hideButton && (
          <Button
            disabled={!plan.id}
            type="submit"
            customWidth="150px"
            customHeight="30px"
            onClick={() => null}>
            Quiero este paquete
          </Button>
        )}
      </ButtonInfoContainer>
      <Spacer size="15" />
      {/*-<Line />
      <Spacer size="15" />*/}
      {/* @TODO: habilitar cuando se tengan examenes tipo simulacros */}
      {/* <Caption2 color="grayBlack" fontWeight={fontWeightType.Medium}>
        {autoTest}
      </Caption2>
      <Spacer size="15" /> */}
      {/*----------------------------------------------------------*/}
      {/*----------@TODO: Implementar logica featureFlag-----------*/}
      {/*
      <InfoContainer>
        <Caption2 color="grayBlack" fontWeight={fontWeightType.SemiBold}>
          {textDate}
        </Caption2>
        <Caption2 color="grayBlack" fontWeight={fontWeightType.Bold}>
          {date}
        </Caption2>
      </InfoContainer>
      <Spacer size="15" />
      <Caption2 color="grayBlack" fontWeight={fontWeightType.SemiBold}>
        {tyc}
        <TycText to="/terms-and-conditions">Aplican Términos y condiciones.</TycText>
      </Caption2>
      */}
      {/*----------------------------------------------------------*/}
      {/*----------------------------------------------------------*/}
    </Background>
  );
};

export default PlanCard;
